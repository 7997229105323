.gallery {
  display: flex;
  flex-wrap: wrap;

  div {
    @include media-breakpoint-up(md) {
      width: 48%;
      margin: 1%;
    }

    img {
      margin: 0;
    }
  }
  &--four{

    div {
      @include media-breakpoint-up(md) {
        width: 23%;
        margin: 1%;
      }

      img {
        margin: 0;
      }
    }
  }
}
