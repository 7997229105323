.history {
  &.slick{
    overflow: hidden;
  }
  &__box {
    height: 300px;
    width: 300px;
    border: 5px solid transparent;
    border-radius: 50%;
    //background-size: cover;
    background: $primary;
    position: relative;
    margin: 0 auto;
    transition: ease-in 0.5s;
    transform: scale(0.8);
    opacity: 1 !important;

    //&--first {
    //  background: #f2ea00 !important;
    //}
    //
    //&--last {
    //  background: #33358b !important;
    //}

    //&--current {
    //  height: 300px;
    //  width: 300px;
    //}

    &:before {
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
      content: '';
      background: white;
      border-radius: 50%;
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
    max-width: 80%;
    margin: 0 auto;
    font-weight: 500;
    .lead{
      font-weight: 500;
    }
  }

  &__head {
    font-size: 42px;
    position: relative;
    z-index: 2;
  }

  //background-height: 10px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    top: 150px;
    //width: 100%;
    //background-image: url(../Images/bar_gradient.svg);
    //background-repeat: repeat-y;
    background: $primary;
    background-size: cover;
  }

  .slick-slide {
    opacity: 1 !important;
  }
  .slick-track{
    padding-top: 20px;
  }
  .slick-active{
    margin-top: -25px;
  }
  .slick-current{
    margin-top: -5px;
  }
}

//.slide-color-0 {
//
//  .history {
//    &__box {
//      background: $primary;
//    }
//  }
//}

//.slide-color-1 {
//
//  .history {
//    &__box {
//      //margin-top: 10px;
//      background: #d90018;
//    }
//  }
//}
//
//.slide-color-2 {
//
//  .history {
//    &__box {
//      background: #F35E1A;
//    }
//  }
//}
