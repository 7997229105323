@mixin brand-colors($color) {
  .btn-primary,
  .bg-brand:not(.btn-no-brand) {
    background-color: $color;
    border-color: $color;
  }
  .btn-primary:not(.btn-no-brand):hover {
    background-color: white;
    color: $color;
    border-color: darken($color, 0.3);
  }
  .text-brand {
    color: $color;
  }
  .border-brand, .btn-outline-primary {
    border-color: $color;
    color: $gray-800;
  }
  .btn-outline-primary:not(.btn-no-brand) {
    &:hover {
      background: $color;
      color: white;
    }
  }
  ul{
    li{
      &:before{
        border-color: $color;
      }
    }
  }
  .product {
    &-mini {
      &:hover {

        .product {
          &-mini {
            &__img {
              &-wrapper {
                &:before {
                  transition: ease 0.2s;
                  background: rgba($color, 0.3)!important;
                }
              }
            }
          }
        }
      }

    }
  }
}

.schema {
  &-first {
    @include brand-colors($first-color);
  }

  &-second {
    @include brand-colors($second-color);
  }

  &-third {
    @include brand-colors($fourth-color);
  }

  &-fourth {
    @include brand-colors($fourth-color);
  }

  &-fiveth {
    @include brand-colors($fiveth-color);
  }

}
