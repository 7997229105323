#toggleMenu {
  //position: absolute;
  //top: 20px;
  //left: 20px;
  z-index: 9999999;
  position: relative;
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}

.main-content {
  -webkit-filter: none;
  -moz-filter: none;
  -o-filter: none;
  -ms-filter: none;
  filter: none;
  transition: ease-out 0.5s;
}

#menu {
  position: fixed;
  top: -200%;
  //bottom: 0;
  left: 0;
  right: 0;
  background: rgba($primary, 0.95);
  z-index: 9999;
  font-size: $font-size-lg*1.3;
  text-align: center;
  overflow: scroll;
  transition: ease-out 0.2s;
  opacity: 0;

  img {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: $font-size-lg;
    }

    a {
      color: white;
      text-decoration: none;
    }
  }

  & > ul {

    & > li {

      &:first-of-type {
        margin-top: 50px;
        margin-bottom: 50px;
      }

      & > a {
        font-weight: bold;
      }

      &:hover {
        //ul {
        //  max-height: 1000px;
        //  transition: ease-out 0.5s;
        //}
      }

      & > ul {
        & > li {
          font-size: $font-size-lg;

        }
      }
    }
  }
}

.mobile-menu-open {
  .main-content {

    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    transition: ease 0.2s;
    //opacity: 0.4;
  }

  #menu {
    opacity: 1;
    top: 0;
    bottom: 0;
    transition: ease-out 0.5s;
  }
}
