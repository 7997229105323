.search-box {
  z-index: 1;
  height: 210px;
  &__header {
    margin-top: 2rem;
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
    //font-weight: bold;
  }

  &__tab {
    cursor: pointer;
    border-top: 2px solid rgba(#BBBBBB, 0.2);
    border-right: 2px solid rgba(#BBBBBB, 0.2);
    align-self: flex-end;
    text-align: center;
    transition: ease-out 0.4s;
    box-shadow: 10px 10px 10px rgba(#00000063, 0);
    padding-bottom: 0;
    padding-top: 0;
    background: white;

    &-link {
      padding-top: 26px;
      padding-bottom: 26px;
    }

    &:first-of-type {
      border-left: 1px solid rgba(#BBBBBB, 0.2);
    }

    &--back {
      background: #F2F2F2;
    }

    &--current {
      cursor: default;
      transition: ease-in 0.2s;
      background-color: #F2F2F2;
      border-color: #F2F2F2 !important;
      padding-bottom: 50px;
      padding-top: 50px;
      box-shadow: 10px 10px 10px rgba(#00000063, 0.3) !important;
      position: static;
    }

    img {
      height: 100px;
      width: auto;
      margin: 0 auto;
    }
  }

  &__content {
    position: relative;
    background-color: #F2F2F2;
    z-index: 2;

    h4, h5 {
      text-shadow: none !important;
    }
  }
}
