
.slide__inner__content {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 250px !important;
  padding-bottom: 250px !important;

  //h1, h2, h3, h4, h5 {
  //  text-shadow: 0 0 6px rgba(black, 0.5);
  //}

  .neos-nodetypes-text {
    //text-shadow: 0 0 6px rgba(black, 0.5);
    text-align: center;
    @include media-breakpoint-up(md) {
      max-width: 50%;
      margin: 40px auto;
    }
    a{
      color: white;
      text-decoration: underline;
    }
  }
}


.slide__inner {
  position: relative !important;

  &__backgroudImage {
    background-attachment: fixed;
    background-size: auto !important;
    @include media-breakpoint-up(md) {
      background-size: cover !important;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  //&:before {
  //  z-index: 1;
  //  position: absolute;
  //  top: 0;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  content: '';
  //  transition: ease-in 0.5s;
  //  background: rgba(black, 0.4);
  //}
}

.slick {
  &-header {
    color: $primary;
    font-size: 150px;
    font-weight: bold;
  }

  &-subheader {
    font-size: 25px;
    font-weight: bold;
  }

  &-content {
    font-size: 27px;
    max-width: 80%;
    margin: 0 auto;
  }
}

.center-mode {
  min-height: 130%;

  .slick-slide {
    opacity: 0.7;
    @include media-breakpoint-up(md) {
      transition: ease 0.3s;
      transform: scale(0.8);
    }

    img {
      max-width: 250px;
      height: auto;
      margin: 0 auto;
    }

    &.slick-current {
      opacity: 1;
      transition: ease 0.3s;
      @include media-breakpoint-up(md) {
        transform: scale(1.2);
      }
    }
  }
}
