.main-content {
  z-index: 1;
  position: relative;
}

.cog {
  &s {
    opacity: 0.1;
    z-index: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: ease 0.5s;

    //& > div {
    //  position: relative;
    //}
    &.visible {
      transition: ease 0.5s;
      opacity: 0.8 !important;

      .cog-logo-img {
        transition: ease 0.5s;
        filter: grayscale(10%) !important;
      }
    }
    &:hover{
      .cog-scene{
        opacity: 0.2;
      }
    }
  }

  &-scene {
    transition: ease-in 1s;
    &:hover {
      //.cog-small, .cog-very-small {
      transform: scale(1.2);
      transition: ease-out 0.2s;
      z-index: 999;
      opacity: 0.9!important;
      //}
    }
  }

  &-logo {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-img {
      max-width: 40%;
      margin: 0 auto;
      filter: grayscale(100%);
      transition: ease 0.5s;
    }
  }

  &-big {
    width: 380px;
    height: 380px;
  }

  &-small {
    width: 260px;
    height: 220px;
  }

  &-very-small {
    width: 180px;
    height: 180px;
  }

  &-absolute {
    position: absolute;
  }

  &-head {
    width: 45%;
  }
}

.main-cog {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -200px;
}
.neos-backend{
  .cogs{
    display: none!important;
  }
}
