.product-page {
  &__brand {
    max-width: 250px;
    height: auto;
  }

  &__table {
    font-size: $font-size-lg;
  }

  &__related {
    min-height: 150px;
    overflow: hidden;

    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
    transition: ease-in 0.2s;

    &-wrapper {
      background-size: cover;
      margin-right: 16px !important;
      margin-bottom: 16px;
      transition: ease-in 0.3s;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.8);
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.8);
        transition: ease-out 0.3s;
        transform: scale(1.3);

        a {
          text-decoration: none;
        }
      }
    }

    &-label {
      margin: auto;
      padding: 16px;

    }

  }
}

.product {
  &-mini {
    &__logo {
      max-width: 100px;
      margin: 0 auto;
    }

    &__img {
      &-wrapper {
        height: 300px;
        position: relative;
        overflow: hidden;
        color: white;

        img {
          margin: 0 auto;
          //top: 0;
          //bottom: 0;
          z-index: 1;
          //left: 0;
          right: 0;
          transition: ease-out 0.5s;
        }


        &:before {
          transition: ease-out 0.5s;
          content: '';
          position: absolute;
          background: rgba(black, 0.3);
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
        }
      }

      &-front {
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: ease-out 0.5s;
      }

      &-back {
        transition: ease-out 0.5s;
        z-index: 3;
        top: 100%;
        bottom: -100%;
        left: 0;
        right: 0;
      }
    }

    &:hover {

      .product {
        &-mini {
          &__img {
            &-wrapper {

              img {
                transform: scale(2);
                transition: ease-in 20s;
              }
            }

            &-front {
              top: -100%;
              bottom: 100%;
              transition: ease-in 0.3s;
            }

            &-back {
              transition: ease-in 0.3s;
              top: 0;
              bottom: 0;
            }
          }

        }
      }
    }
  }
}

.product-row {
  &-sold {
    opacity: 0.5;
  }
}

.product-sold {
  background: $gray-100;

  h3 {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: $gray-500;
  }
}
