.neos-nodetypes-headline {
  .bar {
    margin: 0 auto;
    width: 70px;
    height: 4px;
    overflow: hidden;
    transition: ease 1s;
    margin-top: 40px;

    &__content {
      background: linear-gradient(to left, $primary, white);
      transition: ease 1s;
      height: 150px;
      margin-top: -50%;
    }
  }

  &--bar {
    h1 {
      font-size: $font-size-base * 3.5;
    }

    h2 {
      font-size: $font-size-base * 3;
    }

    h3 {
      font-size: $font-size-base * 2.75;
    }

    h4 {
      font-size: $font-size-base * 2.5;
    }

    h5 {
      font-size: $font-size-base * 2;
    }

    h6 {
      font-size: $font-size-base * 1.5;
    }
  }

  &:hover {
    .bar {
      width: 150px;
      transition: ease 1s;
      //height: 7px;
    }

    .bar__content {
      transition: ease 1s;
      //transform: rotate(720deg);
    }
  }
}
