.header {
  &__container {
    z-index: 100;
    background: rgba($primary, 0.9);
    padding-top: 70px;
    padding-bottom: 70px;
    position: fixed;
    top: 0;
    transition: ease-out 0.3s;
  }

  &__logo {
    //width: 150px;
    //transition: ease-out 1s;
  }

  &__menu {
    position: relative;
    margin: 0 20px;
    padding: 0;
    a{
      color: white;

    }
    & > li {
      & > .current, & > .active {
        font-weight: 700;
      }

      a {
        border-radius: 5px;
        padding: 10px;
        transition: ease 0.2s;
        text-decoration: none;

        &:hover {
          transition: ease 0.2s;
          background: rgba($primary, 1);
          color: white;
        }
      }

      margin-right: 15px;
      display: block;

      & > ul {
        margin: 0;
        padding: 0;
        overflow: hidden;
        max-height: 0;
        transition: ease-in 0.2s;
        position: absolute;
        //left: 0;
        //right: 0;
        display: flex;

        li {
          padding-top: 20px;
          display: block;
          margin-right: 10px;

          a {
            display: block;
            //padding: 20px;
            color: $light;

            &:hover {
              background: $primary;
              color: white;
            }
          }
        }

      }

      &:hover {
        & > ul {
          transition: ease-out 0.5s;
          max-height: 300px;
        }
      }
    }
  }

  &__mobile {
    &-logo {
      //width: 200px;
    }
  }
}

.scroll {
  .header__container {
    padding-top: 10px;
    padding-bottom: 10px;
    transition: ease-out 0.7s;
    background: rgba($primary, 0.99);
    z-index: 9999;

    &:hover {
      padding-top: 20px;
      padding-bottom: 70px;
    }
  }

  .header__logo {
    //width: 120px;
    //transition: ease-in 0.5s;
  }
}

.neos-backend {
  .header__container {
    display: none !important;
  }
}
