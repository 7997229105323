.app_cdi_fav {
  .jonnitto-multicolumn-2 {
    .neos-contentcollection {
      align-self: flex-start;

      &:last-of-type {
        @include media-breakpoint-up(md){
          position: sticky;
          top: 200px;
        }
      }
    }
  }
}
