@import "cssVendors/awesome/scss/fontawesome";
@import "cssVendors/awesome/scss/regular";
@import "cssVendors/awesome/scss/brands";
@import "cssVendors/awesome/scss/solid";
@import "cssVendors/bootstrap/bootstrap";
@import "cssVendors/slick/slick";
@import "cssVendors/slick/slick-theme";
@import "cssVendors/hamburgers/hamburgers";
@import "cssVendors/fancybox";

$first-color: #00872B;
$second-color: #0D909F;
$third-color: #C7D307;
$fourth-color: #751D4F;
$fiveth-color: pink;

@import "component/gallery";
@import "component/form";
@import "component/searchbox";
@import "component/header";
@import "component/slick";
@import "component/history";
@import "component/footer";
@import "component/mobileMenu";
@import "component/news";
@import "component/helpers";
@import "component/breadcrumb";
@import "component/buttons";
@import "component/cache";
@import "component/headline";
@import "component/product";
@import "component/schema";
@import "component/fav";
@import "component/cogs";

.social {
  background: rgba(white, 0.85);
  padding: 10px 5px;
  right: 0;
  top: 30%;
  z-index: 99999999;
}

.single-point {
  display: none;
}

.neos-backend {
  .single-point {
    display: block;
  }
}
